.input {
    height: 45px;
    line-height: 30px;
    padding: 0 1rem;
    border: 2px solid #bebebebe;
    border-radius: 10px;
    outline: none;
    background-color: #f8fafc;
    color: #0d0c22;
    transition: .5s ease;
}

.input::placeholder {
    color: #94a3b8;
}

.input:focus,
.input:hover {
    outline: none;
    border-color: var(--primaryColor);
    background-color: #fff;
    box-shadow: 0 0 0 5px var(--lightPrimaryColor);
}