/* src/index.css */
@import './tailwind.css';
@import '@fortawesome/fontawesome-free/css/all.css';

:root {
  --text-color: #FFFFFF;
  --primaryColor: #03A9F4;
  --lightPrimaryColor: #B3E5FC;
  --darkPrimaryColor: #0288D1;
  --dividerColor: #BDBDBD;
  --secondaryText: #757575;
  --primaryText: #212121;
  --accentColor: #607D8B;
  --secondaryColor: #e2e3e5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: #0288D1 !important;
  border-color: #0288D1 !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #03A9F4 !important;
  border-color: #03A9F4 !important;
  font-weight: bold;
  transition: .1s;
}

.spinner-grow {
  background-color: #0288d1 !important;
}