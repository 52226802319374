.formLogin {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    border-radius: 25px;
    transition: .4s ease-in-out;
    box-shadow: 13px 13px 2px 0px #607D8B;
}

.formLogin:hover {
    transform: translateX(-0.5em) translateY(-0.5em);
}


.group {
    display: flex;
    line-height: 30px;
    align-items: center;
    position: relative;
}

.inputLogin {
    width: 100%;
    height: 45px;
    line-height: 30px;
    padding: 0 5rem;
    padding-left: 3rem;
    border: 2px solid transparent;
    border-radius: 10px;
    outline: none;
    background-color: #f8fafc;
    color: #0d0c22;
    transition: .5s ease;
}

.inputLogin::placeholder {
    color: #94a3b8;
}

.inputLogin:focus,
.inputLogin:hover {
    outline: none;
    border-color: var(--primaryColor);
    background-color: #fff;
    box-shadow: 0 0 0 5px var(--lightPrimaryColor);
}

.icon {
    position: absolute;
    left: 1rem;
    fill: none;
    width: 1rem;
    height: 1rem;
}

/* Button login */
.btnLogin:hover~div {
    background-color: red;
}

.btnLogin {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 500px;
    overflow: hidden;
    background: var(--accentColor);
    color: ghostwhite;
}

.btnLogin span {
    position: relative;
    z-index: 10;
    transition: color 0.9s;
}

.btnLogin:hover span {
    color: var(--text-color);
}

.btnLogin::before,
.btnLogin::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.btnLogin::before {
    content: "";
    background: var(--primaryColor);
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.7s cubic-bezier(0.3, 1, 0.8, 1);
}

.btnLogin:hover::before {
    transform: translate3d(100%, 0, 0);
}

.containerLogin {
    width: 100%;
    height: 100%;
    background: var(--lightPrimaryColor);
    position: relative;
    overflow: hidden;
}

.containerLogin::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, var(--darkPrimaryColor) 10%, transparent 20%),
        radial-gradient(circle, transparent 10%, var(--darkPrimaryColor) 20%);
    background-size: 30px 30px;
    /* Adjust the size of the pattern */
    animation: moveBackground 50s linear infinite;
    /* Adjust the animation duration and timing function */
}

@keyframes moveBackground {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(20%, 20%);
    }
}