.customTooltip {
  position: relative;
}

.customTooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #eeeeeef5;
  color: #282828;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -10%;
  left: 135%;
  opacity: 0;
  transition: opacity 0.3s;
}

.customTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  animation: goPopup 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}


@keyframes goPopup {
  0% {
    transform: translateX(-50%) scaleY(0);
    opacity: 0;
  }

  50% {
    transform: translateX(20%) scaleY(1);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) scaleY(1);
    border-radius: 8px;
    opacity: 1;
  }
}

.customTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 35%;
  left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: #28282817 transparent transparent transparent;
  transform: translateX(-50%) rotate(90deg);
}